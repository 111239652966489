import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import SubHeader from "../components/subHeader"
import ContactFormSection from "../components/contactFormSection"
import InstagramSection from "../components/instagramSection"
import { Container } from "@mui/material"

// temporary
import image from "../images/trial.jpg"

const NotFoundPage = () => {
  return (
    <Layout>
      <Seo title="Not found" />
      <SubHeader title="Not found" image={image} />
      <section className="page404">
        <Container style={{ marginTop: 120, textAlign: "center" }}>
          <title>Not found</title>
          <h1>Page not found</h1>
          <p>
            Sorry{" "}
            <span role="img" aria-label="Pensive emoji">
              😔
            </span>{" "}
            we couldn’t find what you were looking for.
            <br />
            <br />
            <Link to="/">Go home</Link>.
          </p>
        </Container>
      </section>

      <ContactFormSection />
      <InstagramSection />
    </Layout>
  )
}

export default NotFoundPage
